import {
  computed,
  DestroyRef,
  effect,
  inject,
  Injectable,
  Injector,
  runInInjectionContext,
  Signal,
  signal,
} from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { GetAppointmentRecordingQueryService } from '@app/features/healthscribe/graphql/get-appointment-recording.onelife.generated';
import {
  GetRecordingInsightsQuery,
  GetRecordingInsightsQueryService,
  GetRecordingInsightsQueryVariables,
  HealthScribeSettingsFragment,
} from '@app/features/summaries/components/summaries/get-recordings.onelife.generated';
import { Summary } from '../../shared/summaries.type';
import { QueryRef } from 'apollo-angular';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';

interface NestedSignal<T> {
  signal: Signal<T | undefined>;
  setSignal: (signalToSet: Signal<T>) => void;
}

function nestedSignal<T>(): NestedSignal<T> {
  const signalOfSignal = signal<Signal<T | undefined>>(
    signal<T | undefined>(undefined),
  );

  return {
    signal: computed(() => signalOfSignal()()) as Signal<T | undefined>,
    setSignal: (signalToSet: Signal<T>): void => {
      signalOfSignal.set(signalToSet);
    },
  };
}

/**
 * This service handles the initial fetching of an Appointment and the subsequent polling
 * of an associated AppointmentRecording, until we have a recording in the 'transcribed' state.
 */
@Injectable({
  providedIn: 'root',
})
export class AppointmentRecordingQueryService {
  appointmentRecordingIsLoading = signal(true);
  #transcriptNestedSignal =
    nestedSignal<GetRecordingInsightsQuery['appointmentRecording']>();
  transcriptAppointmentRecording = this.#transcriptNestedSignal.signal;
  #settingsNestedSignal = nestedSignal<HealthScribeSettingsFragment>();
  healthScribeSettings = this.#settingsNestedSignal.signal;
  private appointmentId = signal<string | null>(null);

  getAppointmentRecordingQueryService = inject(
    GetAppointmentRecordingQueryService,
  );
  getRecordingInsightsQueryService = inject(GetRecordingInsightsQueryService);
  private injector = inject(Injector);
  private destroyRef = inject(DestroyRef);

  constructor() {
    /**
     * WHEN appointmentID is updated, update the existing polling QueryRef with the new appointmentId
     * IF there was no existing poller set up, create a new poller and set the signal to emit the value when it changes
     */
    effect(
      () => {
        const appointmentId = this.appointmentId();

        if (appointmentId) {
          if (this.appointmentRecordingQueryRef) {
            this.appointmentRecordingQueryRef
              .setVariables({
                id: appointmentId,
              })
              .then(() => this.appointmentRecordingIsLoading.set(false));
          } else {
            this.appointmentRecordingQueryRef =
              this.getRecordingInsightsQueryService.watch(
                {
                  id: appointmentId,
                },
                { fetchPolicy: 'network-only' },
              );
            this.appointmentRecordingQueryRef.valueChanges
              .pipe(takeUntilDestroyed(this.destroyRef))
              .subscribe(result => {
                this.appointmentRecordingIsLoading.set(result.loading);
              });
            queueMicrotask(() => {
              runInInjectionContext(this.injector, () => {
                const result = toSignal(
                  this.appointmentRecordingQueryRef!.valueChanges,
                );
                this.#transcriptNestedSignal.setSignal(
                  computed(() => result()?.data?.appointmentRecording),
                );
                this.#settingsNestedSignal.setSignal(
                  computed(
                    () =>
                      result()?.data?.internalUser
                        ?.settings as HealthScribeSettingsFragment,
                  ),
                );
              });
            });
          }
        }
      },
      {
        allowSignalWrites: true,
      },
    );

    /**
     * WHEN the recording state is _transcribing_, begin polling for changes
     * this is triggered after the initial recording is fetched, and we only
     * know if we need to poll based on its state.
     */
    effect(onCleanup => {
      const recording = this.transcriptAppointmentRecording();
      const appointmentId = this.appointmentId();

      if (
        recording?.state === 'transcribing' &&
        appointmentId !== null &&
        recording?.id === appointmentId
      ) {
        this.appointmentRecordingQueryRef?.startPolling(
          this.transcribingStatePollIntervalMs,
        );

        onCleanup(() => {
          this.appointmentRecordingQueryRef?.stopPolling();
        });
      }
    });
  }

  setAppointmentId(appointmentId: string | null): void {
    this.appointmentId.set(appointmentId);
  }

  /**
   * Resets the appointmentId that the service is polling for information about.
   */
  clearAppointmentId(): void {
    this.appointmentId.set(null);
  }

  /**
   * Initial call that allows us to take a summary and fetch the associated appointment,
   * then appointmentrecording.
   * @param summary The Summary that we're attempting to gather healthscribe information about
   * @returns
   */
  async fetchAppointmentRecording(summary: Summary): Promise<void> {
    if (!summary.appointment) return;

    this.appointmentRecordingIsLoading.set(true);
    const recording = await lastValueFrom(
      this.getAppointmentRecordingQueryService.fetch(
        {
          appointmentId: summary.appointment.id.toString(),
        },
        { fetchPolicy: 'network-only' },
      ),
    );

    if (!recording.data?.appointment?.recording?.id) {
      this.appointmentRecordingIsLoading.set(false);
    }

    if (recording?.data?.appointment?.recording?.id === this.appointmentId()) {
      this.appointmentRecordingIsLoading.set(false);
    }

    this.appointmentId.set(recording.data?.appointment?.recording?.id ?? null);
  }

  private readonly transcribingStatePollIntervalMs: number = 10000;
  private appointmentRecordingQueryRef:
    | QueryRef<GetRecordingInsightsQuery, GetRecordingInsightsQueryVariables>
    | undefined;
}
